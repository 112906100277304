<span
  class="toggle-button dps-icon dps-icon-chevron absolute border-1 border-circle border-400 cursor-pointer surface-0 hover:surface-100 transition-transform transition-duration-300"
  [pTooltip]="
    (isMenuExpanded() ? 'COMPANY_SIDENAV.COLLAPSE' : 'COMPANY_SIDENAV.EXPAND') | translate
  "
  tooltipPosition="right"
  [ngClass]="{ 'rotate-180': isMenuExpanded() }"
  (click)="toggleMenuExpansion()"
></span>

<div class="menu-links flex flex-column gap-2 flex-grow-1 pb-2">
  <a
    pRipple
    [pTooltip]="'COMPANY_SIDENAV.COMPANY_PROFILE' | translate"
    [tooltipDisabled]="isMenuExpanded()"
    [routerLink]="companyProfileRoute"
    [routerLinkActive]="activeLinksClasses"
    class="border-round text-700 hover:surface-100"
  >
    <span class="dps-icon dps-icon-building"></span>
    <span class="link-text">{{ 'COMPANY_SIDENAV.COMPANY_PROFILE' | translate }}</span>
  </a>

  <a
    pRipple
    [pTooltip]="'COMPANY_SIDENAV.USER_ACCOUNTS' | translate"
    [tooltipDisabled]="isMenuExpanded()"
    [routerLink]="companyUserAccountsRoute"
    [routerLinkActive]="activeLinksClasses"
    class="border-round text-700 hover:surface-100"
  >
    <span class="dps-icon dps-icon-key"></span>
    <span class="link-text">{{ 'COMPANY_SIDENAV.USER_ACCOUNTS' | translate }}</span>
  </a>

  @if (!isGroupUser && (isGroupsEnabled$ | async)) {
    <a
      pRipple
      [pTooltip]="'COMPANY_SIDENAV.GROUPS' | translate"
      [tooltipDisabled]="isMenuExpanded()"
      [routerLink]="companyGroupsRoute"
      [routerLinkActive]="activeLinksClasses"
      class="border-round text-700 hover:surface-100"
    >
      <span class="dps-icon dps-icon-groups"></span>
      <span class="link-text">{{ 'COMPANY_SIDENAV.GROUPS' | translate }}</span>
    </a>
  }

  <a
    pRipple
    [pTooltip]="'COMPANY_SIDENAV.NEW_EMPLOYEES' | translate"
    [tooltipDisabled]="isMenuExpanded()"
    [routerLink]="companyInvitationsRoute"
    [routerLinkActive]="activeLinksClasses"
    class="border-round text-700 hover:surface-100"
  >
    <span class="dps-icon dps-icon-person_add"></span>
    <span class="link-text">{{ 'COMPANY_SIDENAV.NEW_EMPLOYEES' | translate }}</span>
  </a>

  <a
    pRipple
    [pTooltip]="'COMPANY_SIDENAV.PLANNING' | translate"
    [tooltipDisabled]="isMenuExpanded()"
    [routerLink]="companyPlanningRoute"
    [routerLinkActive]="activeLinksClasses"
    class="border-round text-700 hover:surface-100"
  >
    <span class="dps-icon dps-icon-event-note"></span>
    <span class="link-text">
      {{ 'COMPANY_SIDENAV.PLANNING' | translate }}
    </span>
  </a>

  @if (isActualsEpicEnabled$ | async) {
    <a
      pRipple
      [pTooltip]="'COMPANY_SIDENAV.ACTUALS' | translate"
      [tooltipDisabled]="isMenuExpanded()"
      [routerLink]="companyActualsRoute"
      [routerLinkActive]="activeLinksClasses"
      queryParamsHandling="merge"
      class="border-round text-700 hover:surface-100"
    >
      <span
        class="dps-icon dps-icon-euro"
        pBadge
        [value]="(companyContractConfirmationsCount$ | async) || 0"
        [badgeDisabled]="isMenuExpanded() || !(companyContractConfirmationsCount$ | async)"
        badgeStyleClass="p-0"
        [badgeStyle]="{ right: '-6px', top: '-3px' }"
      ></span>
      <span class="link-text flex-1">
        {{ 'COMPANY_SIDENAV.ACTUALS' | translate }}
      </span>

      @if (companyContractConfirmationsCount$ | async; as companyContractConfirmationsCount) {
        <p-badge [value]="companyContractConfirmationsCount"></p-badge>
      }
    </a>
  }

  @if (isTimeRegistrationEnabled$ | async) {
    <a
      pRipple
      [pTooltip]="'COMPANY_SIDENAV.TIME_REGISTRATION' | translate"
      [tooltipDisabled]="isMenuExpanded()"
      [routerLink]="companyTimeRegistrationRoute"
      [routerLinkActive]="activeLinksClasses"
      class="border-round text-700 hover:surface-100"
    >
      <span class="dps-icon dps-icon-timer"></span>
      <span class="link-text">
        {{ 'COMPANY_SIDENAV.TIME_REGISTRATION' | translate }}
      </span>
    </a>
  }

  @if (!hasCustomerUserRole) {
    <p-divider styleClass="m-0"></p-divider>
    <a
      pRipple
      [pTooltip]="'COMPANY_SIDENAV.SEARCH_COMPANY' | translate"
      [tooltipDisabled]="isMenuExpanded()"
      [routerLink]="searchRoute"
      [routerLinkActive]="activeLinksClasses"
      class="border-round text-700 hover:surface-100"
    >
      <span class="dps-icon dps-icon-search"></span>
      <span class="link-text">{{ 'COMPANY_SIDENAV.SEARCH_COMPANY' | translate }}</span>
    </a>
  }

  <a
    pRipple
    [pTooltip]="'COMPANY_SIDENAV.LOGOUT' | translate"
    [tooltipDisabled]="isMenuExpanded()"
    [routerLinkActive]="activeLinksClasses"
    [routerLink]="loginRoute"
    (click)="authApiService.logout()"
    class="border-round text-700 hover:surface-100 mt-auto"
  >
    <span class="dps-icon dps-icon-logout"></span>
    <span class="link-text">{{ 'COMPANY_SIDENAV.LOGOUT' | translate }}</span>
  </a>
</div>
