<header class="flex justify-content-between align-items-center p-3">
  <span>
    {{ title }}
  </span>

  <p-inputSwitch
    [ngModel]="toggled"
    (onChange)="toggleChange.emit($event.checked)"
    [disabled]="disabled"
  ></p-inputSwitch>
</header>

<main [@toggleSection]="toggled ? 'expanded' : 'collapsed'">
  <div class="p-2">
    <ng-content></ng-content>
  </div>
</main>
