import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';

import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import { RippleModule } from 'primeng/ripple';
import { BadgeModule } from 'primeng/badge';

import { CompanyRouteEnum } from 'src/app/pages/company/company.routes.model';
import { AppRouteEnum } from 'src/app/app.routes.model';
import { UserRole } from '@dps/shared/models';
import { AuthStore, RootStore } from '@dps/core/store';
import { AuthApiService } from '@dps/core/api/auth';
import { AuthRoutePath } from '../../../pages/auth';
import { FeatureFlagKey, FeatureFlagService } from '@dps/core/feature-flag';

@Component({
  selector: 'dps-main-menu',
  standalone: true,
  imports: [
    CommonModule,
    TooltipModule,
    DividerModule,
    RippleModule,
    TranslatePipe,
    RouterLink,
    RouterLinkActive,
    BadgeModule,
  ],
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex flex-column relative border-right-1 border-300 transition-duration-300 pt-8 z-1',
    '[class.expanded]': 'isMenuExpanded()',
  },
})
export class MainMenuComponent {
  readonly isMenuExpanded = signal(false);
  readonly activeLinksClasses = ['text-primary', 'surface-100'];
  readonly companyInvitationsRoute = ['../', CompanyRouteEnum.INVITATIONS];
  readonly companyProfileRoute = ['../', CompanyRouteEnum.PROFILE];
  readonly companyUserAccountsRoute = ['../', CompanyRouteEnum.USER_ACCOUNTS];
  readonly companyGroupsRoute = ['../', CompanyRouteEnum.GROUPS];
  readonly companyPlanningRoute = ['../', CompanyRouteEnum.PLANNING];
  readonly companyActualsRoute = ['../', CompanyRouteEnum.ACTUALS];
  readonly companyTimeRegistrationRoute = ['../', CompanyRouteEnum.TIME_REGISTRATION];
  readonly searchRoute = ['/', AppRouteEnum.SEARCH];
  readonly loginRoute = ['/', AuthRoutePath.LOGIN];
  readonly isGroupUser = this.authStore.hasRoles([UserRole.GROUP_USER]);
  readonly hasCustomerUserRole = this.authStore.hasRoles([
    UserRole.COMPANY_USER,
    UserRole.GROUP_USER,
  ]);
  readonly isGroupsEnabled$ = this.rootStore.isCompanyGroupsEnabled$();
  readonly isTimeRegistrationEnabled$ = this.rootStore.isCompanyTimeRegistrationEnabled$();
  readonly companyContractConfirmationsCount$ = this.rootStore.getCurrCompanyActualsCount$();
  readonly isActualsEpicEnabled$ = this.featureFlagService.getFlagValue$<boolean>(
    FeatureFlagKey.ACTUALS_EPIC
  );

  constructor(
    private authStore: AuthStore,
    private rootStore: RootStore,
    private featureFlagService: FeatureFlagService,
    protected authApiService: AuthApiService
  ) {}

  toggleMenuExpansion(): void {
    this.isMenuExpanded.update(isExpanded => !isExpanded);
  }
}
