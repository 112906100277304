<p-card [subheader]="label" class="w-full" pDroppable (onDrop)="fileDropped($event)">
  <ng-template pTemplate="header">
    @if (media) {
      <img [src]="mediaKey | mediaFileSource: this.publicUpload | async" [alt]="media.media.name" />
    } @else {
      <div class="file-header-placeholder flex justify-content-center align-items-center">
        <span class="dps-icon dps-icon-contact_page text-7xl"></span>
      </div>
    }
  </ng-template>

  <ng-template pTemplate="content">
    <div class="flex flex-column p-error text-sm text-center">
      @if (errors[fileValidationErrorsNameEnum.SIZE_EXCEEDED]; as maxSizeError) {
        <span>{{
          'MEDIA_CARD.FILE_SIZE_EXCEEDED_ERROR' | translate: { maxSize: maxSizeError }
        }}</span>
      }
      @if (errors[fileValidationErrorsNameEnum.INVALID_TYPE]) {
        <span>{{ 'MEDIA_CARD.FILE_TYPE_INVALID_ERROR' | translate }}</span>
      }
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="flex flex-wrap justify-content-evenly">
      @if (media) {
        <a
          [href]="mediaKey | mediaFileSource: this.publicUpload | async"
          target="_blank"
          class="p-button p-button-outlined p-button-secondary gap-2 no-underline"
          download
        >
          <span class="dps-icon dps-icon-download"></span>
          <span> {{ 'MEDIA_CARD.DOWNLOAD_BUTTON' | translate }} </span>
        </a>
        <p-button
          [label]="'MEDIA_CARD.REMOVE_BUTTON' | translate"
          icon="dps-icon dps-icon-delete"
          severity="danger"
          [outlined]="true"
          [loading]="(inProcess$ | async) || false"
          (onClick)="removeFile()"
        ></p-button>
      } @else {
        <p-button
          [label]="'MEDIA_CARD.UPLOAD_BUTTON' | translate"
          icon="dps-icon dps-icon-add"
          [loading]="(inProcess$ | async) || false"
          (onClick)="fileInput.click()"
        ></p-button>
      }
    </div>
  </ng-template>
</p-card>

<input type="file" accept=".jpg,.png,.pdf" #fileInput hidden (change)="fileSelected($event)" />
