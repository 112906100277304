<h3>{{ dialogData?.title || 'LOGOUT_CONFIRM_DIALOG.TITLE' | translate }}</h3>

<span class="dps-icon dps-icon-warning text-8xl text-primary"></span>

<div class="flex w-full justify-content-evenly">
  <button
    class="p-button p-button-text align-self-stretch justify-content-center"
    (click)="dialogRef.close(false)"
  >
    {{ dialogData?.cancelBtnText || 'GENERIC_CONFIRM_DIALOG.CANCEL_BTN' | translate }}
  </button>
  <button
    class="p-button align-self-stretch justify-content-center"
    (click)="dialogRef.close(true)"
  >
    {{ dialogData?.confirmBtnText || 'GENERIC_CONFIRM_DIALOG.CONFIRM_BTN' | translate }}
  </button>
</div>
