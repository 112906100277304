<header class="flex align-items-center gap-3">
  <h3 class="flex-1 m-0 font-medium">
    @if (isCancelMode) {
      <span>{{ 'CONTRACT.CANCEL_CONTRACT_FOR' | translate }}</span>
    } @else if (isCreateMode) {
      <span>{{ 'CONTRACT.NEW_CONTRACT_FOR' | translate }}</span>
    } @else if (isEditMode) {
      <span>{{ 'CONTRACT.UPDATE_CONTRACT_FOR' | translate }}</span>
    }

    <span class="text-primary">{{ data.employee.name }}</span>
  </h3>

  <div class="flex align-items-center">
    @if (canCancelContract) {
      <p-button
        [text]="true"
        [plain]="true"
        icon="dps-icon dps-icon-delete"
        severity="danger"
        [pTooltip]="'CONTRACT.CANCEL_CONTRACT' | translate"
        tooltipPosition="top"
        (onClick)="enableCancelMode()"
      ></p-button>
      <p-divider layout="vertical" styleClass="mx-1 min-h-0 h-1rem"></p-divider>
    }
    <p-button
      [text]="true"
      [plain]="true"
      icon="dps-icon dps-icon-close"
      (onClick)="dialogRef.close()"
    ></p-button>
  </div>
</header>

@if (isLoadingContractData$ | async) {
  <div class="flex flex-1 align-items-center justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
} @else {
  <p-calendar
    selectionMode="range"
    appendTo="body"
    rangeSeparator=">"
    inputStyleClass="w-15rem"
    iconDisplay="input"
    [showIcon]="true"
    [readonlyInput]="true"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [formControl]="datesRangeControl"
  ></p-calendar>

  <div class="flex justify-content-between gap-3">
    @if (isCreateMode) {
      @if (wages$ | async; as wages) {
        <div class="flex flex-column flex-auto">
          <p-dropdown
            appendTo="body"
            [options]="wages"
            [formControl]="wageControl"
            [placeholder]="'CONTRACT.EMPLOYEE_WAGE_LABEL' | translate"
          >
            <ng-template pTemplate="selectedItem">
              @if (wageControl.value; as selectedWage) {
                <span>{{ selectedWage.position }}</span>
                <span> - </span>
                <span> {{ selectedWage.statute.name }} </span>
              }
            </ng-template>
            <ng-template let-wage pTemplate="item">
              <span>{{ wage.position }}</span>
              <span> - </span>
              <span> {{ wage.statute.name }} </span>
            </ng-template>
          </p-dropdown>
          @if (wageControl.errors?.['required']) {
            <small class="p-error">
              {{ 'CONTRACT.SELECT_EMPLOYEE_WAGE' | translate }}
            </small>
          }
        </div>
      } @else {
        <p-skeleton styleClass="w-15rem h-2rem"></p-skeleton>
      }
    } @else {
      <input
        type="text"
        pInputText
        [value]="originalContract.position + ' - ' + originalContract.statute.name"
        [disabled]="true"
        class="flex-auto"
      />
    }
  </div>

  @if (form.controls.employmentAddress.value) {
    <dps-address-autocomplete-field
      [label]="'CONTRACT.EMPLOYMENT_ADDRESS_LABEL' | translate"
      [control]="form.controls.employmentAddress"
    ></dps-address-autocomplete-field>
  }

  @if (isCancelMode) {
    <section class="flex flex-column p-3 gap-3 border-round dps-bg-color-light-gray">
      <h4 class="m-0 text-center">{{ 'CONTRACT.WHY_CANCEL_CONTRACT' | translate }}</h4>
      @for (item of cancelReasons$ | async; track item.code) {
        <div class="flex gap-2">
          <p-radioButton
            [inputId]="item.code"
            [value]="item"
            [formControl]="form.controls.cancelReason"
          ></p-radioButton>
          <label [for]="item.code">{{ item.name }}</label>
        </div>
      }

      <textarea
        rows="5"
        cols="30"
        pInputTextarea
        style="resize: none"
        [placeholder]="'CONTRACT.CANCEL_EXTRA_INFO' | translate"
        [formControl]="form.controls.cancelExtraInfo"
      ></textarea>
    </section>
  } @else if (isTabsFormVisible) {
    <p-tabView>
      <p-tabPanel [header]="'CONTRACT.TABS_LABELS.GENERAL' | translate">
        <ng-template pTemplate="content" [ngTemplateOutlet]="scheduleTemplate"> </ng-template>
      </p-tabPanel>
      <p-tabPanel [header]="'CONTRACT.TABS_LABELS.WAGE' | translate">
        <ng-template pTemplate="content" [ngTemplateOutlet]="wageTabTemplate"> </ng-template>
      </p-tabPanel>
      <p-tabPanel [header]="'CONTRACT.TABS_LABELS.INVOICING' | translate">
        <ng-template pTemplate="content" [ngTemplateOutlet]="invoicingTabTemplate"> </ng-template>
      </p-tabPanel>
      <p-tabPanel [header]="'CONTRACT.TABS_LABELS.OTHER' | translate">
        <ng-template pTemplate="content" [ngTemplateOutlet]="otherTabTemplate"> </ng-template>
      </p-tabPanel>
    </p-tabView>
  } @else {
    <ng-container [ngTemplateOutlet]="scheduleTemplate"></ng-container>
  }

  <footer class="flex flex-column gap-2 text-center">
    @if (
      selectedScheduleDayForm.controls.toTime.enabled &&
      selectedScheduleDayForm.hasError(lateContractErrorName)
    ) {
      <span class="p-error"> {{ 'VALIDATION_ERRORS.LATE_CONTRACT' | translate }}</span>
    } @else if (selectedScheduleDayForm.hasError(maxContractDurationErrorName)) {
      <span class="p-error">
        {{
          'VALIDATION_ERRORS.MAX_CONTRACT_DURATION'
            | translate
              : {
                  maxDurationHours: selectedScheduleDayForm.getError(maxContractDurationErrorName)
                    .maxDurationHours
                }
        }}</span
      >
    }

    @if (form.hasError(extraStatuteMultiDayContractErrorName)) {
      <span class="p-error">
        {{ 'VALIDATION_ERRORS.EXTRA_STATUTE_MULTI_DAY_CONTRACT' | translate }}
      </span>
    }

    @if (isCancelMode) {
      <p-button
        [label]="'CONTRACT.CANCEL_CONTRACT' | translate"
        [loading]="(isProcessingContract$ | async) || false"
        [disabled]="form.invalid"
        (onClick)="cancelContract()"
      ></p-button>
    } @else if (isEditMode) {
      <p-button
        [label]="'CONTRACT.UPDATE_CONTRACT_BUTTON' | translate"
        [loading]="(isProcessingContract$ | async) || false"
        [disabled]="form.invalid"
        (onClick)="updateContract()"
      ></p-button>
    } @else if (isCreateMode) {
      @if (scheduleFormArray.valid && isAllContractSchedulesConfirmed) {
        <p-button
          [label]="'CONTRACT.CONFIRM_CONTRACT_BUTTON' | translate"
          [loading]="(isProcessingContract$ | async) || false"
          [disabled]="form.invalid || !isAllContractSchedulesConfirmed"
          (onClick)="createContract()"
        ></p-button>
      } @else {
        <span class="p-error">
          {{ 'CONTRACT.FILL_IN_ALL_CONTRACT_SCHEDULES' | translate }}
        </span>
      }
    }
  </footer>
}

<ng-template #scheduleTemplate>
  <section class="flex flex-column border-round p-3 dps-bg-color-light-gray">
    <div class="flex align-items-center justify-content-center gap-2">
      <p-button
        icon="dps-icon dps-icon-chevron rotate-180"
        [text]="true"
        [plain]="true"
        [ngStyle]="{
          visibility: isSingleDayContract || isFirstScheduleDaySelected ? 'hidden' : 'visible'
        }"
        (onClick)="selectScheduleDayIndex(selectedScheduleDayIndex - 1)"
      ></p-button>
      <span class="font-bold line-height-4">{{ selectedScheduleFormattedDate }}</span>
      <p-button
        icon="dps-icon dps-icon-chevron"
        [text]="true"
        [plain]="true"
        [ngStyle]="{
          visibility: isSingleDayContract || isLastScheduleDaySelected ? 'hidden' : 'visible'
        }"
        (onClick)="selectScheduleDayIndex(selectedScheduleDayIndex + 1)"
      ></p-button>
    </div>

    @if (isCreateMode) {
      <div class="py-2">
        <p-selectButton
          [options]="shiftTypeOptions"
          [allowEmpty]="false"
          [unselectable]="true"
          [formControl]="useExistingShiftControl"
        >
          <ng-template let-shiftType pTemplate>
            <span class="text-sm">{{ shiftType.labelTranslationKey | translate }}</span>
          </ng-template>
        </p-selectButton>
      </div>

      @if (useExistingShiftControl.value) {
        <p-autoComplete
          styleClass="w-full"
          inputId="shiftNameAutocomplete"
          optionLabel="name"
          appendTo="body"
          dataKey="id"
          [dropdown]="true"
          [showClear]="true"
          [forceSelection]="true"
          [delay]="200"
          [placeholder]="'CONTRACT.SEARCH_SHIFT_PLACEHOLDER' | translate"
          [suggestions]="(shifts$ | async) || []"
          [minLength]="minShiftNameLength"
          [formControl]="shiftAutocompleteControl"
          (completeMethod)="shiftSearchQuery.next($event.query)"
        >
          <ng-template let-shift pTemplate="item">
            <div class="flex justify-content-between align-items-center gap-2">
              <span>{{ shift.name }}</span>
              <span class="z-1 underline hover:no-underline" (click)="removeShift($event, shift)">
                {{ 'CONTRACT.REMOVE_SHIFT_TEMPLATE' | translate }}
              </span>
            </div>
          </ng-template>
        </p-autoComplete>
        @if (shiftAutocompleteControl.invalid) {
          <small class="p-error">
            {{ 'CONTRACT.SELECT_SHIFT' | translate }}
          </small>
        }
      } @else if (selectedScheduleDayForm.controls.createShiftTemplate.value) {
        <input
          type="text"
          pInputText
          [formControl]="selectedScheduleDayForm.controls.shiftTemplateName"
          [placeholder]="'CONTRACT.NEW_SHIFT_INPUT_PLACEHOLDER' | translate"
        />
      }
      @if (
        selectedScheduleDayForm.controls.shiftTemplateName.hasError('required') &&
        selectedScheduleDayForm.controls.createShiftTemplate.value
      ) {
        <small class="p-error">
          {{ 'CONTRACT.GIVE_SHIFT_NAME' | translate }}
        </small>
      }
    }

    @if (isDayScheduleSectionShown) {
      <section
        class="transition-all transition-duration-200 relative"
        [ngClass]="{
          'dps-bg-color-platinum p-2 border-round-bottom':
            isCreateMode &&
            (useExistingShiftControl.value ||
              selectedScheduleDayForm.controls.createShiftTemplate.value),
          'can-copy-schedule': canCopySchedule
        }"
      >
        <div class="grid align-items-center">
          <div class="col-3"></div>
          <div class="col text-center">{{ 'CONTRACT.SCHEDULE_FROM' | translate }}</div>
          <div class="col-1"></div>
          <div class="col text-center">{{ 'CONTRACT.SCHEDULE_UNTIL' | translate }}</div>
        </div>

        <div class="grid align-items-center">
          <div class="col-3">{{ 'CONTRACT.SCHEDULE_WORK_HOURS' | translate }}</div>
          <div class="col flex justify-content-center">
            <dps-time-field [control]="selectedScheduleDayForm.controls.fromTime"></dps-time-field>
          </div>
          <div class="col-1 flex justify-content-center">
            <span class="dps-icon dps-icon-arrow_right_long"></span>
          </div>
          <div class="col flex justify-content-center">
            <dps-time-field [control]="selectedScheduleDayForm.controls.toTime"></dps-time-field>
          </div>
        </div>

        <div class="grid align-items-center">
          <div class="col-3">{{ 'CONTRACT.SCHEDULE_PAUSE_HOURS' | translate }}</div>
          <div class="col flex justify-content-center">
            <dps-time-field
              [control]="selectedScheduleDayForm.controls.pauseFromTime"
            ></dps-time-field>
          </div>
          <div class="col-1 flex justify-content-center">
            <span class="dps-icon dps-icon-arrow_right_long"></span>
          </div>
          <div class="col flex justify-content-center">
            <dps-time-field
              [control]="selectedScheduleDayForm.controls.pauseToTime"
            ></dps-time-field>
          </div>
        </div>

        <p-button
          class="copy-schedule"
          appendTo="body"
          [icon]="
            (isScheduleCopied$ | async) ? 'dps-icon dps-icon-check' : 'dps-icon dps-icon-copy'
          "
          [text]="true"
          [plain]="true"
          [pTooltip]="'CONTRACT.COPY_SCHEDULE_TOOLTIP' | translate"
          (onClick)="copySchedule()"
        ></p-button>
      </section>
    }

    @if (isCreateMode && !useExistingShiftControl.value) {
      <div class="flex align-items-center gap-2 pt-3">
        <p-checkbox
          [binary]="true"
          [formControl]="selectedScheduleDayForm.controls.createShiftTemplate"
          inputId="createShiftTemplate-{{ selectedScheduleDayIndex }}"
        ></p-checkbox>
        <label for="createShiftTemplate-{{ selectedScheduleDayIndex }}">
          {{ 'CONTRACT.CREATE_SHIFT_TEMPLATE_CHECKBOX' | translate }}
        </label>
      </div>
    }
  </section>

  @if (!isSelectedScheduleDayHasMinWorkDuration) {
    <span class="text-center p-error">
      {{ 'VALIDATION_ERRORS.MIN_CONTRACT_WORK_DURATION_PER_PC' | translate }}
    </span>
  }
</ng-template>

<ng-template #wageTabTemplate>
  <div class="flex flex-column gap-3">
    <div class="flex flex-column">
      <label class="mb-1" for="hourlyWage">
        {{ 'EMPLOYEE_WAGE.GROSS_HOURLY_WAGE_LABEL' | translate }}
      </label>
      <p-inputNumber
        inputId="hourlyWage"
        mode="currency"
        currency="EUR"
        [minFractionDigits]="4"
        styleClass="w-full"
        [formControl]="form.controls.wageHour"
      >
      </p-inputNumber>
      <dps-field-validation-errors [control]="form.controls.wageHour">
        @if (form.controls.wageHour.errors?.['min']; as minErrorObj) {
          <small class="p-error">{{
            'VALIDATION_ERRORS.EMPLOYEE_WAGE_MIN'
              | translate
                : {
                    min: minErrorObj.min | currency
                  }
          }}</small>
        }
        @if (form.controls.wageHour.errors?.['max']; as maxErrorObj) {
          <small class="p-error">{{
            'VALIDATION_ERRORS.EMPLOYEE_WAGE_MAX'
              | translate
                : {
                    max: maxErrorObj.max | currency
                  }
          }}</small>
        }
      </dps-field-validation-errors>
    </div>
    <dps-toggle-card
      [title]="'EMPLOYEE_WAGE.TRANSPORTATION_ALLOWANCE_LABEL' | translate"
      [toggled]="travelAllowanceForm.controls.isEnabled.value"
      (toggleChange)="travelAllowanceForm.controls.isEnabled.setValue($event)"
    >
      <div class="flex flex-column gap-3">
        <div class="flex flex-column">
          <label class="mb-1" for="transportationType">
            {{ 'EMPLOYEE_WAGE.TRANSPORTATION_TYPE_LABEL' | translate }}
          </label>
          <p-dropdown
            [options]="(travelAllowances$ | async) || []"
            [optionLabel]="dictionaryItemOptionLabel"
            [dataKey]="dictionaryItemOptionValue"
            [autoOptionFocus]="false"
            inputId="transportationType"
            styleClass="w-full"
            appendTo="body"
            [formControl]="travelAllowanceForm.controls.travelAllowance"
          ></p-dropdown>
          <dps-field-validation-errors
            [control]="travelAllowanceForm.controls.travelAllowance"
          ></dps-field-validation-errors>
        </div>
        <div class="flex flex-column">
          <label class="mb-1" for="transportationDistance">
            {{ 'EMPLOYEE_WAGE.TRANSPORTATION_DISTANCE_LABEL' | translate }}
          </label>
          <p-inputNumber
            inputId="transportationDistance"
            styleClass="w-full"
            mode="decimal"
            [maxFractionDigits]="4"
            [formControl]="travelAllowanceForm.controls.distanceKm"
          >
          </p-inputNumber>
          <dps-field-validation-errors
            [control]="travelAllowanceForm.controls.distanceKm"
          ></dps-field-validation-errors>
        </div>
        <div class="flex flex-column">
          <label class="mb-1" for="transportationAmount">
            {{ 'EMPLOYEE_WAGE.TRANSPORTATION_AMOUNT_LABEL' | translate }}
          </label>
          <p-inputNumber
            inputId="transportationAmount"
            mode="currency"
            currency="EUR"
            [minFractionDigits]="4"
            styleClass="w-full"
            [formControl]="travelAllowanceForm.controls.forfait"
          >
          </p-inputNumber>
          <dps-field-validation-errors
            [control]="travelAllowanceForm.controls.forfait"
          ></dps-field-validation-errors>
        </div>
      </div>
    </dps-toggle-card>

    <dps-toggle-card
      [title]="'EMPLOYEE_WAGE.MEAL_VOUCHERS_LABEL' | translate"
      [toggled]="mealVoucherForm.controls.isEnabled.value"
      (toggleChange)="mealVoucherForm.controls.isEnabled.setValue($event)"
    >
      <div class="flex flex-column gap-3">
        <div class="flex flex-column">
          <label class="mb-1" for="mealVouchersTotal">
            {{ 'EMPLOYEE_WAGE.MEAL_VOUCHERS_TOTAL_LABEL' | translate }}
          </label>
          <p-inputNumber
            inputId="mealVouchersTotal"
            mode="currency"
            currency="EUR"
            [minFractionDigits]="2"
            styleClass="w-full"
            [formControl]="mealVoucherForm.controls.shareTotal"
          >
          </p-inputNumber>
          <dps-field-validation-errors [control]="mealVoucherForm.controls.shareTotal">
            @if (mealVoucherForm.controls.shareTotal.errors?.['min']; as minErrorObj) {
              <small class="p-error">{{
                'VALIDATION_ERRORS.MEAL_VOUCHERS_TOTAL_MIN'
                  | translate
                    : {
                        min: minErrorObj.min | currency
                      }
              }}</small>
            }
            @if (mealVoucherForm.controls.shareTotal.errors?.['max']; as maxErrorObj) {
              <small class="p-error">{{
                'VALIDATION_ERRORS.MEAL_VOUCHERS_TOTAL_MAX'
                  | translate
                    : {
                        max: maxErrorObj.max | currency
                      }
              }}</small>
            }
          </dps-field-validation-errors>
        </div>
        <div class="flex flex-column">
          <label class="mb-1" for="firstName">
            {{ 'EMPLOYEE_WAGE.MEAL_VOUCHERS_EMPLOYER_SHARE_LABEL' | translate }}
          </label>
          <p-inputNumber
            inputId="transportationAmount"
            mode="currency"
            currency="EUR"
            [minFractionDigits]="2"
            styleClass="w-full"
            [formControl]="mealVoucherForm.controls.shareCompany"
          >
          </p-inputNumber>
          <dps-field-validation-errors
            [control]="mealVoucherForm.controls.shareCompany"
          ></dps-field-validation-errors>
        </div>
        <div class="flex flex-column">
          <label class="mb-1" for="firstName">
            {{ 'EMPLOYEE_WAGE.MEAL_VOUCHERS_EMPLOYEE_SHARE_LABEL' | translate }}
          </label>
          <p-inputNumber
            inputId="transportationAmount"
            mode="currency"
            currency="EUR"
            [minFractionDigits]="2"
            styleClass="w-full"
            [formControl]="mealVoucherForm.controls.shareEmployee"
          >
          </p-inputNumber>
          <dps-field-validation-errors [control]="mealVoucherForm.controls.shareEmployee">
            @if (mealVoucherForm.controls.shareEmployee.errors?.['min']; as minErrorObj) {
              <small class="p-error">
                {{
                  'VALIDATION_ERRORS.MEAL_VOUCHERS_EMPLOYEE_MIN'
                    | translate
                      : {
                          min: minErrorObj.min | currency
                        }
                }}
              </small>
            }
          </dps-field-validation-errors>
        </div>
        <div class="flex flex-column">
          <label class="mb-1" for="firstName">
            {{ 'EMPLOYEE_WAGE.MEAL_VOUCHERS_MIN_WORK_HOURS_LABEL' | translate }}
          </label>
          <p-inputNumber
            inputId="transportationAmount"
            styleClass="w-full"
            [formControl]="mealVoucherForm.controls.minimumHours"
          >
          </p-inputNumber>
          <dps-field-validation-errors
            [control]="mealVoucherForm.controls.minimumHours"
          ></dps-field-validation-errors>
        </div>
      </div>
    </dps-toggle-card>

    <div class="flex justify-content-between align-items-center">
      <label for="ecoVouchers">{{ 'EMPLOYEE_WAGE.ECO_VOUCHERS_LABEL' | translate }}</label>
      <p-inputSwitch
        inputId="ecoVouchers"
        [formControl]="form.controls.invoiceEcoWeekly"
      ></p-inputSwitch>
    </div>
  </div>
</ng-template>

<ng-template #invoicingTabTemplate>
  <div class="flex flex-column gap-3">
    <div class="flex flex-column">
      <label class="mb-1" for="coefficient">
        {{ 'CONTRACT.INVOICING.COEFFICIENT' | translate }}
      </label>
      <p-inputNumber
        inputId="coefficient"
        [minFractionDigits]="4"
        styleClass="w-full"
        [formControl]="invoicingForm.controls.coefficient"
      >
      </p-inputNumber>
      <dps-field-validation-errors [control]="invoicingForm.controls.coefficient">
      </dps-field-validation-errors>
    </div>
    <div class="flex flex-column">
      <label class="mb-1" for="coefficientBankHoliday">
        {{ 'CONTRACT.INVOICING.COEFFICIENT_BANK_HOLIDAY' | translate }}
      </label>
      <p-inputNumber
        inputId="coefficientBankHoliday"
        [minFractionDigits]="4"
        styleClass="w-full"
        [formControl]="invoicingForm.controls.coefficientBankHoliday"
      >
      </p-inputNumber>
      <dps-field-validation-errors [control]="invoicingForm.controls.coefficientBankHoliday">
      </dps-field-validation-errors>
    </div>
    <div class="flex flex-column">
      <label class="mb-1" for="coefficientTravelAllowance">
        {{ 'CONTRACT.INVOICING.COEFFICIENT_TRANSPORTATION_ALLOWANCE' | translate }}
      </label>
      <p-inputNumber
        inputId="coefficientTravelAllowance"
        [minFractionDigits]="4"
        styleClass="w-full"
        [formControl]="invoicingForm.controls.coefficientTravelAllowance"
      >
      </p-inputNumber>
      <dps-field-validation-errors [control]="invoicingForm.controls.coefficientTravelAllowance">
      </dps-field-validation-errors>
    </div>
    <div class="flex flex-column">
      <label class="mb-1" for="coefficientMealVouchers">
        {{ 'CONTRACT.INVOICING.COEFFICIENT_MEALVOUCHERS' | translate }}
      </label>
      <p-inputNumber
        inputId="coefficientMealVouchers"
        [minFractionDigits]="4"
        styleClass="w-full"
        [formControl]="invoicingForm.controls.coefficientMealVouchers"
      >
      </p-inputNumber>
      <dps-field-validation-errors [control]="invoicingForm.controls.coefficientMealVouchers">
      </dps-field-validation-errors>
    </div>
    <div class="flex flex-column">
      <label class="mb-1" for="coefficientEcoVouchers">
        {{ 'CONTRACT.INVOICING.COEFFICIENT_ECOVOUCHERS' | translate }}
      </label>
      <p-inputNumber
        inputId="coefficientEcoVouchers"
        [minFractionDigits]="4"
        styleClass="w-full"
        [formControl]="invoicingForm.controls.coefficientEcoVouchers"
      >
      </p-inputNumber>
      <dps-field-validation-errors [control]="invoicingForm.controls.coefficientEcoVouchers">
      </dps-field-validation-errors>
    </div>
    <div class="flex flex-column">
      <label class="mb-1" for="dimonaCost">
        {{ 'CONTRACT.INVOICING.DIMONA_COST' | translate }}
      </label>
      <p-inputNumber
        inputId="dimonaCost"
        [minFractionDigits]="4"
        styleClass="w-full"
        [formControl]="invoicingForm.controls.dimonaCost"
      >
      </p-inputNumber>
      <dps-field-validation-errors [control]="invoicingForm.controls.dimonaCost">
      </dps-field-validation-errors>
    </div>
    <div class="flex flex-column">
      <label class="mb-1" for="defaultTaxRate">
        {{ 'CONTRACT.INVOICING.STANDARD_TAX_TARIFF' | translate }}
      </label>
      <p-dropdown
        inputId="defaultTaxRate"
        styleClass="w-full"
        [options]="(defaultTaxRates$ | async) || []"
        [optionLabel]="dictionaryItemOptionLabel"
        [formControl]="invoicingForm.controls.defaultTaxRate"
      ></p-dropdown>
      <dps-field-validation-errors [control]="invoicingForm.controls.defaultTaxRate">
      </dps-field-validation-errors>
    </div>
  </div>
</ng-template>

<ng-template #otherTabTemplate>
  <div class="flex flex-column gap-3">
    <div class="flex flex-column">
      <label class="mb-1" for="reason">
        {{ 'CONTRACT.EMPLOYMENT_REASON' | translate }}
      </label>
      <p-dropdown
        inputId="reason"
        appendTo="body"
        styleClass="w-full"
        [options]="(reasons$ | async) || []"
        [optionLabel]="dictionaryItemOptionLabel"
        [formControl]="form.controls.reason"
      ></p-dropdown>
      <dps-field-validation-errors [control]="form.controls.reason"></dps-field-validation-errors>
    </div>
    <div class="flex flex-column">
      <label class="mb-1" for="compensationHours">
        {{ 'CONTRACT.COMPENSATION_HOURS' | translate }}
      </label>
      <p-dropdown
        inputId="compensationHours"
        appendTo="body"
        styleClass="w-full"
        [options]="(compensationHours$ | async) || []"
        [optionLabel]="dictionaryItemOptionLabel"
        [formControl]="form.controls.compensationHours"
      ></p-dropdown>
      <dps-field-validation-errors [control]="form.controls.compensationHours">
      </dps-field-validation-errors>
    </div>
    <div class="flex flex-column">
      <h4 class="mt-0 mb-3 text-primary">{{ 'CONTRACT.INTERN_HEADER' | translate }}</h4>
      <div class="flex flex-column">
        <label class="mb-1" for="revenueConsultant">
          {{ 'CONTRACT.DEFAULT_REVENUE_SALES_REP' | translate }}
        </label>
        <p-dropdown
          [options]="(consultants$ | async) || []"
          dataKey="id"
          [autoOptionFocus]="false"
          inputId="revenueConsultant"
          styleClass="w-full"
          appendTo="body"
          [formControl]="form.controls.revenueConsultant"
        >
          <ng-template pTemplate="selectedItem">
            <ng-container
              [ngTemplateOutlet]="revenueConsultantOptionTemplate"
              [ngTemplateOutletContext]="{ revConsultant: form.controls.revenueConsultant.value }"
            ></ng-container>
          </ng-template>

          <ng-template let-revConsultant pTemplate="item">
            <ng-container
              [ngTemplateOutlet]="revenueConsultantOptionTemplate"
              [ngTemplateOutletContext]="{ revConsultant }"
            ></ng-container>
          </ng-template>
        </p-dropdown>
        <dps-field-validation-errors
          [control]="form.controls.revenueConsultant"
        ></dps-field-validation-errors>
      </div>
    </div>
    <div class="flex flex-column">
      <h4 class="mt-0 mb-3 text-primary">{{ 'CONTRACT.WORKSCHEDULE_HEADER' | translate }}</h4>
      <label class="mb-1" for="employeeHourPerWeek">
        {{ 'CONTRACT.EMPLOYEE_HOURS_WEEKLY' | translate }}
      </label>
      <p-inputNumber
        inputId="employeeHourPerWeek"
        styleClass="w-full"
        [minFractionDigits]="2"
        [suffix]="'CONTRACT.HOURS_WEEKLY_SUFFIX' | translate"
        [formControl]="form.controls.employeeHoursPerWeek"
      >
      </p-inputNumber>
      <dps-field-validation-errors [control]="form.controls.employeeHoursPerWeek">
      </dps-field-validation-errors>
    </div>
    <div class="flex flex-column">
      <label class="mb-1" for="companyHoursPerWeek">
        {{ 'CONTRACT.COMPANY_HOURS_WEEKLY' | translate }}
      </label>
      <p-inputNumber
        inputId="companyHoursPerWeek"
        styleClass="w-full"
        [minFractionDigits]="2"
        [suffix]="'CONTRACT.HOURS_WEEKLY_SUFFIX' | translate"
        [formControl]="form.controls.companyHoursPerWeek"
      >
      </p-inputNumber>
      <dps-field-validation-errors [control]="form.controls.companyHoursPerWeek">
      </dps-field-validation-errors>
    </div>
  </div>
</ng-template>

<ng-template let-revConsultant="revConsultant" #revenueConsultantOptionTemplate>
  @if (revConsultant) {
    <span>{{ revConsultant.firstName }}</span>
    <span>&nbsp;</span>
    <span>{{ revConsultant.lastName }}</span>
  }
</ng-template>
