<div class="flex align-items-center">
  <img src="assets/images/dps-logo.svg" alt="dps-logo" class="w-8rem hidden md:inline-block" />
  <p-divider layout="vertical" class="hidden md:inline-block"></p-divider>
  <h3 class="text-primary font-medium m-0" [innerHTML]="title()"></h3>

  @if (subtitle()) {
    <p-divider layout="vertical"></p-divider>
    <h3 class="font-medium m-0" [innerHTML]="subtitle()"></h3>
  }
</div>

<div class="flex-grow-1">
  <ng-content></ng-content>
</div>

<dps-app-locale-selector class="hidden md:block align-self-center"></dps-app-locale-selector>
