import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectButtonModule } from 'primeng/selectbutton';

import { AppLocaleEnum } from '@dps/core/i18n';

@UntilDestroy()
@Component({
  selector: 'dps-app-locale-selector',
  standalone: true,
  imports: [SelectButtonModule, ReactiveFormsModule],
  templateUrl: './app-locale-selector.component.html',
  styleUrl: './app-locale-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLocaleSelectorComponent implements OnInit {
  constructor(private translateService: TranslateService) {}

  readonly locales = this.translateService.getLangs();
  readonly localeControl = new FormControl<AppLocaleEnum>(
    this.translateService.currentLang as AppLocaleEnum,
    { nonNullable: true }
  );

  ngOnInit(): void {
    this.localeControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(selectedLocale => this.translateService.use(selectedLocale));
  }
}
